import React from "react";

const data = [
  {
    factor: "Number of Employees Required",
    manual: "20 employees",
    automated: "2-3 employees for oversight"
  },
  {
    factor: "Employee Salary (per month per person)",
    manual: "₹30,000",
    automated: "₹30,000"
  },
  {
    factor: "Total Employee Cost (per month)",
    manual: "₹6,00,000 (20 × ₹30,000)",
    automated: "₹60,000 - ₹90,000 (2-3 × ₹30,000)"
  },
  {
    factor: "Infrastructure Cost",
    manual: "Office space, utilities, travel expenses",
    automated: "Minimal (Cloud-based, remote access)"
  },
  {
    factor: "Accuracy & Efficiency",
    manual: "Prone to human errors, delayed issue detection",
    automated: "Real-time issue detection, 24/7 monitoring"
  },
  {
    factor: "Scalability",
    manual: "Expensive to scale (more billboards = more employees)",
    automated: "Easily scalable (add more billboards with minimal cost)"
  },
  {
    factor: "Monitoring Capabilities",
    manual: "Periodic physical checks, manual reports",
    automated: "Live camera streaming, automated reports, AI-based anomaly detection"
  },
  {
    factor: "Response Time",
    manual: "Hours to days",
    automated: "Instant alerts, real-time issue detection"
  },

  {
    factor: "Total Yearly Cost for 70 Billboards",
    manual: "₹72,00,000+",
    automated: "₹9,60,000 - ₹10,80,000"
  },
  {
    factor: "Cost Reduction Percentage",
    manual: "0%",
    automated: "85% - 87% reduction"
  }
];

const CostComparisonTable = () => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Cost Comparison: Manual vs Automated Billboard Monitoring (for 70 billboards)</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 shadow-lg">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3 border">Factor</th>
              <th className="p-3 border">Manual Monitoring</th>
              <th className="p-3 border">Automated Monitoring</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="p-3 border text-center">{item.factor}</td>
                <td className="p-3 border text-center">{item.manual}</td>
                <td className="p-3 border text-center">{item.automated}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CostComparisonTable;
