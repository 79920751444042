import { formatPrice } from "@/lib/utils";
import React from "react";

function roundToNearest(num) {
  return Math.ceil(num / 500) * 500;
}

const commonCost = {
  "Hosting cost": 5000,
  "DB cost": 5000,
  "Tunnel": 2000,
  camera:3000,
  rpiZero: 2500,
  rpi5Kit8Gb:11000,
  rpi4Kit8Gb:9000, 
  cameraInstallation: 1000,
  support:1000,
  ai30min:1500,
}

const devices = 30
const mode = window.location.search.includes('dev') ? 'dev' : 'prod';

const data = {
  "firms": {
    "Small (0-5)": 0,
    "Medium (6-20)": 10,
    "Large (21-50)": 15,
    "Enterprise (Above 50)": 20
  },
  "services": [
    {
      "name": "Billboard Live Streaming",
      "costs": {
        setup:[
          commonCost.camera,
          commonCost.rpiZero,
          commonCost.cameraInstallation
        ],
        recurring:[
          commonCost["Hosting cost"],
          commonCost["DB cost"],
          commonCost["Tunnel"],
          commonCost.support
        ],
      },
      price:{
        setup: 12000,
        subscription: 500
      }
    },
   
    {
      "name": "Billboard Traffic Analysis",
      "costs": {
        setup:[
          commonCost.camera,
          commonCost.rpi4Kit8Gb,
          commonCost.cameraInstallation
        ],
        recurring:[
          commonCost["Hosting cost"],
          commonCost["DB cost"],
          commonCost["Tunnel"],
          commonCost.support
        ],
      },
      price:{
        setup: 20000,
        subscription: 500
      }
    },
    {
      "name": "Billboard Alerts without Live Streaming",
      "costs": {
        setup:[
          commonCost.camera,
          commonCost.rpiZero,
          commonCost.cameraInstallation
        ],
        recurring:[
          commonCost["Hosting cost"],
          commonCost["DB cost"],
          commonCost["Tunnel"],
          commonCost.support,
          commonCost.ai30min
        ],
       
      },
      price:{
        setup: 10000,
        subscription: 3000
      }
    },
    {
      "name": "Billboard Alerts with Live Streaming",
      "costs": {
        setup:[
          commonCost.camera,
          commonCost.rpiZero,
          commonCost.cameraInstallation
        ],
        recurring:[
          commonCost["Hosting cost"],
          commonCost["DB cost"],
          commonCost["Tunnel"],
          commonCost.support,
          commonCost.ai30min
        ],
       
      },
      price:{
        setup: 12000,
        subscription: 3500
      }
    },
    {
      "name": "Billboard Alerts with Live streaming & Traffic Analysis",
      "costs": {
        setup:[
          commonCost.camera,
          commonCost.rpi4Kit8Gb,
          commonCost.cameraInstallation
        ],
        recurring:[
          commonCost["Hosting cost"],
          commonCost["DB cost"],
          commonCost["Tunnel"],
          commonCost.support,
          commonCost.ai30min
        ],
      },
      price:{
        setup: 22000,
        subscription: 4000
      }
    }
  ],
  "calculatePricing": function(firmSize, quantity) {
    const discount = this.firms[firmSize] / 100;
    return this.services.map(service => {
      const setupCost = service.costs.setup.reduce((acc, cost) => acc + cost, 0);
      const recurringCost = service.costs.recurring.reduce((acc, cost) => acc + cost, 0)/devices + (service.costs.recurring.includes(commonCost.ai30min) ? commonCost.ai30min : 0);

      const setupPrice = service.price.setup;
      const subscriptionPrice = service.price.subscription;

      const discountedSetupPrice = roundToNearest(setupPrice * (1 - discount));
      const discountedSubscriptionPrice = roundToNearest(subscriptionPrice * (1 - discount));
      
      const firstYearPrice = (discountedSetupPrice + discountedSubscriptionPrice * 12) * quantity;
      const secondYearPrice = discountedSubscriptionPrice * 12 * quantity;

      const firstYearCost = (setupCost + (recurringCost * 12)) * quantity;
      const secondYearCost = recurringCost * quantity * 12;
      
      const setupMargin = ((discountedSetupPrice - setupCost) / discountedSetupPrice) * 100;
      const subscriptionMargin = ((discountedSubscriptionPrice - recurringCost) / discountedSubscriptionPrice) * 100;
       
      return {
        quantity: quantity,
        service: service.name,
        setupCost: setupCost,
        discountedSetupPrice: `${discountedSetupPrice.toFixed(0)}`,
        recurringCost: `${recurringCost}`,
        discountedSubscriptionPrice: `${discountedSubscriptionPrice.toFixed(0)}`,
        firstYearCost: firstYearCost,
        secondYearCost: `${secondYearCost.toFixed(0)}`,
        firstYearPrice: firstYearPrice,
        secondYearPrice: secondYearPrice,
        setupMargin: `${setupMargin.toFixed(0)}%`,
        subscriptionMargin: `${subscriptionMargin.toFixed(0)}%`
      };
    });
  }
}

function showProfit(item,year){
  if(mode=='dev'){
    if(year == 1 ){
      return "("+formatPrice(item.firstYearPrice - item.firstYearCost)+")"
    }
    if(year == 2 ){
      return "("+formatPrice(item.secondYearPrice - item.secondYearCost)+")"
    }
    
  }
  return ""
}

function DevBlock({children}){
  if(mode=='dev'){
    return children
  }
  return ""
}

function Table({firmSize,quantity}){
  const smallData = data.calculatePricing(firmSize, quantity)

  return (
    <div className="lg:p-6 bg-white rounded-xl shadow-md overflow-x-auto">

      <div className="flex justify-between items-center mb-4">

        <h2 className="font-semibold text-xl">
          Showing cost for {quantity} devices
        </h2>
        <div className="text-sm"> 
          * Alert interval is 30 minutes
        </div>
      </div>

      <table className="w-full border-collapse border border-gray-300">  
        <thead>
          <tr className="bg-gray-100">
            <th className="p-2 border">Service</th>
            <th className="p-2 border">Setup/Camera</th>
            <th className="p-2 border">Subscription/Camera)</th>
            <th className="p-2 border">1st Year Cost</th>
            <th className="p-2 border">2nd Year Cost</th>
          </tr>
        </thead>
        <tbody>
          {smallData.map((item) => {
            return (
              <tr key={item.service}>
                <td className="p-2 border">{item.service}</td>
                <td className="p-2 border text-center">{formatPrice(item.discountedSetupPrice)} 
                  <DevBlock>({formatPrice(item.setupCost)}) Margin: {item.setupMargin}</DevBlock>
                </td>
                <td className="p-2 border text-center">{formatPrice(item.discountedSubscriptionPrice)}
                  <DevBlock>
                   ({formatPrice(item.recurringCost)}) Margin: {item.subscriptionMargin}
                  </DevBlock>
                </td>
                <td className="p-2 border text-center">{formatPrice(item.firstYearPrice,2)} 
                  <DevBlock>
                    {showProfit(item,1)}
                  </DevBlock>
                </td>
                <td className="p-2 border text-center">{formatPrice(item.secondYearPrice,2)} 
                  <DevBlock>{showProfit(item,2)}</DevBlock>
                </td>
              </tr>
            );           
          })}
        </tbody>
      </table>
    </div>
  )
}

const PricingTableForm = ({onSelect})=>{
  const [quantities] = React.useState([1,2,10,50,70]);
  const [selectedQuantity,setSelectedQuantity] = React.useState(2);
  return <>
    <div className="lg:py-6 border-b">
     
      <div className="grid grid-cols-1 gap-4 px-2 ">
       
        <label className="text-sm font-semibold">Select number of screens</label>
        <div className="flex gap-2">
          {quantities.map((quantity)=>{
            return <button key={quantity} 
              type="button" 
              className={`text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500 ${selectedQuantity==quantity ? "bg-blue-700 text-white" : ""}`}
              onClick={()=>{
                setSelectedQuantity(quantity)
                onSelect(quantity)
              }}
            >
              {quantity}
            </button>
          })}
          
        </div>
       
      </div>
    </div>
  </>
}

const PricingTable = () => {

  const [quantity, setQuantity] = React.useState(2);

  function getFirmSize(quantity:number){
    if(quantity<=5){
      return "Small (0-5)"
    }else if(quantity<=20){
      return "Medium (6-20)"
    }else if(quantity<=50){
      return "Large (21-50)"
    }else{
      return "Enterprise (Above 50)"
    }
  }

  return <div className="flex gap-4 flex-col overflow-x-auto">
    <PricingTableForm onSelect={setQuantity}/>
    <Table firmSize={getFirmSize(quantity)} quantity={quantity}></Table>
  </div>  
};

export default PricingTable;