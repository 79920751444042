import useUserContext from '@/hooks/useUserContext'
import { mutation, useTypedLazyQuery } from '@/hooks/useZeus'
import React, { useEffect } from 'react'

type WishlistContextType = {
  wishlistedAdBoardIds:string[]
  addAdboardToWishlist:(adboardId:string)=>Promise<void>
  removeAdboardFromWishlist:(adboardId:string)=>Promise<void>,
  toggleAdboardInWishlist:(adboardId:string)=>Promise<void>
}

const WishlistContext = React.createContext({} as WishlistContextType)

export default function WishlistProvider({
  children
}:{
  children:React.ReactNode
}) {

  const {user} = useUserContext()
  const [getWishlist, {data,refetch}] = useTypedLazyQuery({
    wishlists:[{
      where:{
        userId:{
          equals: user?.id
        }
      }
    },{
      id:true,
      adBoardIds:true
    }]
  })
  const wishlistId = data?.wishlists?.[0]?.id;

  useEffect(()=>{
    if(user && !wishlistId){
      // Create a new wishlist for the user
      mutation({
        createOneWishlist:[{
          data:{
            name:"Default",
            adBoards:{
              connect:[]
            },
            user:{
              connect:{
                id:user.id
              }
            }
          }
        },{
          id:true
        }]
      }).then(refetch)
    }
  },[wishlistId])

  useEffect(()=>{
    if(user){
      // Fetch wishlist data from the server
      getWishlist();
    }
  },[user])

  async function addAdboardToWishlist(adboardId:string){
    // Add adboard to wishlist
    await mutation({
      updateOneWishlist:[{
        where:{
          id:wishlistId
        },
        data:{
          adBoards:{
            connect:[{
              id:adboardId
            }]
          }
        }
      },{
        adBoardIds:true
      }]
    })
    refetch()
  }

  async function removeAdboardFromWishlist(adBoardid:string){
    // Remove adboard from wishlist
    await mutation({
      updateOneWishlist:[{
        where:{
          id:wishlistId
        },
        data:{
          adBoards:{
            disconnect:[{
              id:adBoardid
            }]
          }
        }
      },{
        adBoardIds:true
      }]
    })
    refetch()
  }

  async function toggleAdboardInWishlist(adboardId:string){
    // Check if adboard is already in wishlist
    if(data?.wishlists?.[0]?.adBoardIds.includes(adboardId)){
      // If adboard is in wishlist, remove it
      await removeAdboardFromWishlist(adboardId)
    }else{
      // If adboard is not in wishlist, add it
      await addAdboardToWishlist(adboardId)
    }
  }

  return (
    <WishlistContext.Provider value={{
      wishlistedAdBoardIds:data?.wishlists?.[0]?.adBoardIds||[],
      addAdboardToWishlist,
      removeAdboardFromWishlist,
      toggleAdboardInWishlist
    }}>
      {children}
    </WishlistContext.Provider>
  )
}

export const useWishlistContext = () => {
  return React.useContext(WishlistContext)
}
