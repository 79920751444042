import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function setBrevoPageTitle(){
  if (typeof window.Brevo !== 'undefined') {
    const pageTitle = document.title;
    const pageUrl = window.location.href;

    window.Brevo.push([
      'track',
      'page',
      {
        name: pageTitle,
        url: pageUrl
      }
    ]);
  }
}

const useBrevoPageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    setBrevoPageTitle();
  }, [location]);
};

export default useBrevoPageTracking;
