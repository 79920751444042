import BackgroundSection from "@/components/BackgroundSection/BackgroundSection";
import PricingTable from "./PricingTable";
import SectionClientSay from "./WhatClientSay";
import CostComparisonTable from "./CostComparisonTable";
import Box from "@/components/Box";

export default function AICameraSolutionsPage() {
  return (
    <div className='container mx-auto px-4 py-8'>
      
      <SectionClientSay/>

      <div className="relative lg:py-8 pb-8 lg:mt-8">
        <BackgroundSection />
        <div
          className={`nc-SectionHowItWork`}
          data-nc-id="SectionHowItWork"
        >
         
          <div className="relative grid gap-2">
            <section className="py-16 max-w-5xl mx-auto">
              <h2 className="text-3xl font-semibold text-center">Key Benefits</h2>
              <div className="mt-8 grid md:grid-cols-3 gap-8">
          
                <div className="bg-white p-6 rounded-lg shadow-md text-center">
                  <h3 className="font-semibold text-xl">24/7 Surveillance</h3>
                  <p className="mt-2 text-gray-700">Continuous monitoring without human intervention.</p>

                </div>
                <div className="bg-white p-6 rounded-lg shadow-md text-center">
                  <h3 className="font-semibold text-xl">Cloud Storage & Reports</h3>
                  <p className="mt-2 text-gray-700">Store and access historical data for insights.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md text-center">
                  <h3 className="font-semibold text-xl">Energy Efficient</h3>
                  <p className="mt-2 text-gray-700">Low power consumption for long-term savings.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md text-center">
                  <h3 className="font-semibold text-xl">Customizable Alerts</h3>
                  <p className="mt-2 text-gray-700">Set alerts based on specific conditions.</p>
                </div>
                <div> 
                  <div className="bg-white p-6 rounded-lg shadow-md text-center">
                    <h3 className="font-semibold text-xl">Remote Access</h3>
                    <p className="mt-2 text-gray-700">Monitor from anywhere via a web or mobile dashboard.</p>
                  </div>
                </div>
                <div>
                  <div className="bg-white p-6 rounded-lg shadow-md text-center">
                    <h3 className="font-semibold text-xl">AI Analytics & Insights</h3>
                    <p className="mt-2 text-gray-700">Generate reports on ad effectiveness or traffic patterns.</p>
                  </div>
                </div>
          
                <div className="bg-white p-6 rounded-lg shadow-md text-center">
                  <h3 className="font-semibold text-xl">Improved Security</h3>
                  <p className="mt-2 text-gray-700">Helps prevent vandalism and unauthorized access.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md text-center">
                  <h3 className="font-semibold text-xl">Multi-Location Management</h3>
                  <p className="mt-2 text-gray-700">Manage multiple cameras from a single dashboard.</p>
                </div>
                <div>
                  <div className="bg-white p-6 rounded-lg shadow-md text-center">
                    <h3 className="font-semibold text-xl">Cost Savings</h3>
                    <p className="mt-2 text-gray-700">Reduces the need for manual monitoring and maintenance.</p>
                  </div>  
                </div>
              </div>

            </section>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
     
      <div className="mt-8 lg:mx-10 overflow-x-auto">
      </div>
      <Box title="Price calculator">
        <PricingTable/>
      </Box>
      <div className="mt-8 lg:mx-10">
        <CostComparisonTable/>
      </div>

      <section className="py-16 lg:px-6 max-w-3xl mx-auto" id="get-in-touch">
        <h2 className="text-3xl font-semibold text-center">Get in Touch</h2>
        <p className="text-center text-gray-600 mt-2">Have questions? Let’s talk.</p>
        <iframe className="mx-auto" src="https://docs.google.com/forms/d/e/1FAIpQLSd24ZpKfsWBursLU4GzLHbAW2EQoS62gJhOKPGYujVC0u2qew/viewform?embedded=true" width="100%" height="900" frameBorder={0} marginHeight={0} marginWidth={0}>Loading…</iframe>
      </section>
      
      {/* Contact Section */}
     
    </div>
  );
}
