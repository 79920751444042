import { ApolloProviderWrapper } from '@/context/ApolloClientContext';
import { AppProvider } from '@/context/AppContext';
import CartProvider from '@/context/CartContext'; 
import { FirebaseProvider } from '@/context/FirebaseContext';
import { UserProvider } from '@/context/UserContext'; 
import { ToastContainer } from '@/lib/toast';
import MyRouter from "@/routers/index";
import {APIProvider} from '@vis.gl/react-google-maps';

import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact,{BugsnagErrorBoundary} from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import WishlistProvider from './context/WishlistProvider';
import { GMAP_API_KEY } from './contains/contants';
import WhiteLabelProvider from './context/WhiteLabelProvider';

Bugsnag.start({
  apiKey: '4b9d4a8ea6de9d0446560ee6fa8a6ab3', 
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '4b9d4a8ea6de9d0446560ee6fa8a6ab3' })

function App() { 
  const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) as BugsnagErrorBoundary
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
    
      <APIProvider apiKey={GMAP_API_KEY} onLoad={() => console.log('Maps API has loaded.')}>
        <ErrorBoundary>
          
          <FirebaseProvider >
            <UserProvider>
              <ApolloProviderWrapper>
                <WhiteLabelProvider>
                  <AppProvider>
                    <WishlistProvider>
                      <CartProvider>
                        <MyRouter />
                        <ToastContainer
                          autoClose={2000}
                          position="top-right"
                          hideProgressBar={false}
                          newestOnTop={false}
                          rtl={false}
                          draggable
                          closeOnClick={true}
                        />
                      </CartProvider>
                    </WishlistProvider>
                  </AppProvider>
                </WhiteLabelProvider>
                  
              </ApolloProviderWrapper>
            </UserProvider> 
          </FirebaseProvider>
        </ErrorBoundary>
      </APIProvider>
    </div>
  );
}

export default App;
